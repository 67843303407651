
<template>
    <div class="box">
        <div class="box-header">
            <h3 class="box-title">Seznam úkonů</h3>
        </div>
        <div class="box-body">
            <div class="row">
                <div class="col-lg-4">
                    <div class="input-group input-group-sm" style="width: 100%">
                        <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat">
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="input-group input-group-sm" style="width: 100%">
                        <button style="width: 25%" type="button" class="btn btn-default btn-flat btn-sm" :class="{'active btn-primary': showByStage == issueType.id}" v-for="issueType in this.issueTypes" :key="issueType.id" @click="showByStage = issueType.id">{{issueType.name}}</button>
                    </div>
                </div>
                <div class="col-lg-4" v-if="!user.cus">
                    <router-link :to="{ name: 'IssueAdd' }" class="btn btn-default btn-flat btn-sm pull-right"><i class="fa fa-plus"></i> Přidat úkon</router-link>
                </div>
            </div>
            <br>
            <div class="table-responsive no-padding" style="width: auto; overflow: auto">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th v-for="head in this.headColumns" :key="head.title">{{head.name}}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="issue in getIssues" :key="issue.issueid">
                            <td><span class="label" :style="`background-color: ${issue.tag_color};`">{{issue.tag_title}}</span></td>
                            <td style="cursor: pointer" @click="makeCurrentIssue(issue.issueid)"><a>{{issue.visible_id}}</a></td>
                            <td style="cursor: pointer" @click="makeCurrentIssue(issue.issueid)"><a>{{issue.name}}</a></td>
                            <td style="cursor: pointer" @click="makeCurrentCar(issue.carid)"><a>{{issue.car_name}}</a></td>
                            <td style="cursor: pointer" @click="makeCurrentCus(issue.customerid)"><a>{{issue.company_name}}</a></td>
                            <td style="cursor: pointer" @click="makeCurrentMnt(issue.maintainerid)"><a>{{issue.mnt_name}}</a></td>
                            <td>{{issue.due_from_str}}</td>
                            <td>{{issue.due_to_str}}</td>
                            <td>{{issue.added}}</td>
                            <td v-if="threshold && showByStage === 1">
                                <div :class="issue.email_sent === 0 ? 'btn btn-xs' : ''" @click="sendIssueEmail(issue)">
                                {{["Poslat","Odesláno","Odesílání"][issue.email_sent]}}<i v-if="issue.email_sent === 2" class="fa fa-spin fa-refresh"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="!loaded" class="overlay">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
    </div>
</template>

<script>
import api from "@/store/classes/api";

const name = 'Issues';

const ISSUE_TYPES = {
    VSECHNO: 0,
    PLANOVANO: 1,
    OTEVRENO: 4,
    UZAVRENO: 3,
}

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    data() {
        const showByStage = parseInt(this.$route.query.showByStage) || 0
        const threshold = parseInt(this.$route.query.threshold) || 0
        this.$router.replace({ 'query': null })
        const headColumns = [
            { name: 'Stav' },
            { name: 'Číslo úkonu' },
            { name: 'Úkon' },
            { name: 'Auto' },
            { name: 'Zákazník' },
            { name: 'Osoba' },
            { name: 'Od' },
            { name: 'Do' },
            { name: 'Evidováno' },
        ]
        if (threshold && showByStage === 1) {
            headColumns.push({name: "Odeslat email"})
        }

        return {
            filter: '',
            showByStage,
            loaded: false,
            threshold,
            headColumns,
        };
    },
    computed: {
        ...mapGetters(["getAllPages", 'allIssues', 'user', 'group']),
        getIssues() {
            var allIssues = this.allIssues
            if (!allIssues.length)
            {
                return []
            }

            const xDays = Math.floor((new Date().getTime())/1000 + this.threshold*86400)
            // Filtr provedeme na vícero položkách, zároveň filtrujeme podle typu úkonu (Plánováno, atd.)
            const filteredIssues = allIssues.filter(item => {
                return (
                    (this.showByStage === item.tagid || this.showByStage == ISSUE_TYPES.VSECHNO)
                    && (this.$helpers.filterCheck(this.filter, item.name)
                        || this.$helpers.filterCheck(this.filter, item.car_name)
                        || this.$helpers.filterCheck(this.filter, item.visible_id)
                        || this.$helpers.filterCheck(this.filter, item.company_name)
                        || this.$helpers.filterCheck(this.filter, item.mnt_name)
                        )
                    && (!this.threshold || item.due_from <= xDays)
                );
            })

            return filteredIssues
        }
    },
    methods: {
        ...mapActions(["makeActiveTab", "addTab", "fetchIssues", 'makeCurrentIssue', 'makeCurrentCar', 'makeCurrentCus', 'makeCurrentMnt', 'fetchUser', 'fetchMaintainer']),
        getTime(epochTime) {
            const dt = new Date(epochTime * 1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            const hrs = dt.getHours();
            let min = dt.getMinutes();

            if (min < 10)
            {
                min = '0' + min.toString();
            }

            return `${day}.${mth}.${yrs}  ${hrs}:${min}`;
        },
        async sendIssueEmail(issue) {
            //Do not send again
            if (issue.email_sent !== 0) return
            //1 - sent, 2 sending, 0 not sent
            issue.email_sent = 2;
            const maintainer = await api.get(`maintainers/${issue.maintainerid}`)

            const mail = {
                sourceid: "issue-"+issue.issueid,
                "from": this.group.email,
                to: maintainer.email,
                subject: "Upozornění na blížící se servisní úkon",
                html: `Dobrý den,<br>v termínu od ${this.$helpers.getDate(issue.due_from)} jste objednán na ${issue.name}. V případě, že se nemůžete dostavit, prosím ozvěte se.<br>${this.group.company_name}`,
                issueid: issue.issueid,
            }

            const res = await api.post("email/send",mail)

            if (res === "OK") {
                issue.email_sent = 1
            }
        }
    },
    created() {
        this.fetchIssues().then(() => { // Loads all issues into this.allIssues
            this.loaded = true;

            this.allIssues.forEach((issue) => {
                if (issue.due_from > 0)
                {
                    issue.due_from_str = this.getTime(issue.due_from);
                }

                if (issue.due_to > 0)
                {
                    issue.due_to_str = this.getTime(issue.due_to);
                }
                issue.added = this.getTime(issue.added);
            });
        });

        // We put constants to created() method, putting them into data() would make them reactive (to state -> useless..)
        this.ISSUE_TYPES = ISSUE_TYPES

        this.issueTypes = [
            {
                name: "Plánováno",
                id: ISSUE_TYPES.PLANOVANO
            },
            {
                name: "Otevřeno",
                id: ISSUE_TYPES.OTEVRENO
            },
            {
                name: "Uzavřeno",
                id: ISSUE_TYPES.UZAVRENO
            },
            {
                name: "Všechny",
                id: ISSUE_TYPES.VSECHNO
            },
        ]

        const page = this.getAllPages.filter(page => page.name == name)[0]
        this.addTab(page);
        this.makeActiveTab(page);
    },
    watch: {
        showByStage() {
            if (this.threshold) {
                if (this.showByStage === 1) {
                    if (! ({name: "Odeslat email"} in this.headColumns)){
                        this.headColumns.push({name: "Odeslat email"})
                    }
                }
                else {
                    this.headColumns = this.headColumns.filter(item => item.name !== "Odeslat email")
                }
            }
        }
    }
}
</script>
